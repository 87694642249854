/*---
Custom Color add to theme
---*/
.premium .primium-offer {
  display: block; }

.no-premium .navbar {
  margin-top: 40px; }

.no-premium .my-tournament-wrapper {
  margin-top: 112px; }

.my-tournament-wrapper {
  margin-top: 72px;
  background-image: linear-gradient(to top, #ffffff, #f8faff); }
  .my-tournament-wrapper .card-wrapper [class^="col-"] {
    padding-right: 10px; }
  .my-tournament-wrapper .card-wrapper .card.card-my-tournament {
    margin-bottom: 20px;
    width: 212px;
    height: 340px;
    border-radius: 18px;
    box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
    background: transparent; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #3e68ff;
      text-align: center;
      color: #fff;
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.1); }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header.nickx {
        background-color: #fc7323; }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header.mtn {
        background-color: #000000; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-img {
      height: 170px;
      border-radius: 0px;
      object-fit: cover; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body {
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      background: #fff; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament.premium .card-header {
      background-color: #5b00ac; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock {
      position: relative; }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 37px);
        top: 37px;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(224, 228, 232, 0.7));
        border-radius: 18px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px; }
        @media (max-width: 767.98px) {
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock::after {
            height: 89%;
            top: 11%; } }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .card-img {
        opacity: 0.4;
        filter: grayscale(80%);
        object-fit: cover; }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .card-body {
        opacity: 0.4; }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info img {
          width: 100px; }
          @media (max-width: 767.98px) {
            .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info img {
              width: 70px; } }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament .lock-info {
      display: none; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament:hover {
      box-shadow: 0 6px 26px -10px rgba(60, 64, 85, 0.4); }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info img {
      width: 80px; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info h6 {
      font-size: 12px; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info h2 {
      font-size: 21px;
      margin-top: 0.7rem; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info .btn-premium.unlock {
      width: 82%;
      line-height: 2.5; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body {
      padding: 0rem 2rem; }
      @media (max-width: 767.98px) {
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body {
          padding: 0rem 1.2rem; } }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body h4 {
        font-size: 18px; }
    .my-tournament-wrapper .card-wrapper .card.card-my-tournament figure {
      margin-bottom: 7px;
      margin-top: -30px; }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament figure img {
        width: 56px;
        height: auto;
        margin-left: -10px; }
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament figure figcaption {
        margin-top: 2px;
        color: #1a46e5;
        font-weight: 500; }
    @media (max-width: 767.98px) {
      .my-tournament-wrapper .card-wrapper .card.card-my-tournament {
        width: 200px;
        height: 320px;
        border-radius: 16px;
        box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
        background: transparent; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header {
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          padding-top: 8px;
          padding-bottom: 8px;
          background-color: #3e68ff;
          text-align: center;
          color: #fff;
          box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.1); }
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header.nickx {
            background-color: #fc7323; }
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header.mtn {
            background-color: #000000; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-img {
          height: 176px;
          border-radius: 0px;
          object-fit: cover; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body {
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          background: #fff; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament.premium .card-header {
          background-color: #5b00ac; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock {
          position: relative; }
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: calc(100% - 37px);
            top: 37px;
            width: 100%;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(224, 228, 232, 0.7));
            border-radius: 16px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px; } }
      @media (max-width: 767.98px) and (max-width: 767.98px) {
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock::after {
          height: 89%;
          top: 11%; } }
    @media (max-width: 767.98px) {
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .card-img {
            opacity: 0.4;
            filter: grayscale(80%);
            object-fit: cover; }
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .card-body {
            opacity: 0.4; }
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info img {
              width: 100px; } }
        @media (max-width: 767.98px) and (max-width: 767.98px) {
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament.lock .lock-info img {
            width: 70px; } }
    @media (max-width: 767.98px) {
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .lock-info {
          display: none; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament:hover {
          box-shadow: 0 6px 26px -10px rgba(60, 64, 85, 0.4); }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-header {
          font-size: 12px; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body h4 {
          font-size: 16px;
          font-weight: bold; }
        .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body figure {
          margin-top: -30px; }
          .my-tournament-wrapper .card-wrapper .card.card-my-tournament .card-body figure figcaption {
            font-size: 12px; } }
