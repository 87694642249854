/*---
Custom Color add to theme
---*/
.pd-2 {
  padding: 7rem 0rem; }
  @media (max-width: 575.98px) {
    .pd-2 {
      padding: 7rem 0rem; } }
  @media (max-width: 1199.98px) {
    .pd-2 {
      padding: 5rem 0rem; } }
  @media (max-width: 991.98px) {
    .pd-2 {
      padding: 6rem 0rem; } }
  @media (max-width: 767.98px) {
    .pd-2 {
      padding: 2rem 0rem; } }

@media (max-width: 767.98px) {
  .news-detail-section {
    margin-top: -105px !important; } }

.mb-6 {
  margin-bottom: 4rem; }

.banner-wrapper.aboutus-wrapper {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
  position: relative; }
  .banner-wrapper.aboutus-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/images/about-background.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1; }
  .banner-wrapper.aboutus-wrapper.nickx::after {
    background-image: url("../../assets/images/about-background.png"); }

span.btn-outline-secondary:hover {
  color: #fff !important; }

span.btn-secondary {
  color: #fff !important; }

.banner.aboutus {
  background-image: url("../../assets/images/aboutus-banner.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: contain;
  background-position: top right;
  z-index: 2; }
  .banner.aboutus .about-content {
    margin-top: 15rem; }
  .banner.aboutus .about-quote {
    margin-top: 10rem; }
  @media (max-width: 767.98px) {
    .banner.aboutus {
      background-image: none; }
      .banner.aboutus .about-content {
        margin-top: 2rem; }
      .banner.aboutus .about-quote {
        margin-top: 4rem; } }
  .banner.aboutus.nickx {
    background-image: url("../../assets/images/about-us-img-nick.png"); }
  .banner.aboutus.mtn {
    background-image: url("../../assets/images/about-us-img-mtn.png"); }

.about-collumn-section {
  margin-top: 10rem;
  z-index: 2;
  padding-bottom: 5rem; }
  .about-collumn-section .about-sec-img {
    max-width: 40%; }
  .about-collumn-section [class^="col-"] {
    padding-left: 25px;
    padding-right: 25px; }
  @media (max-width: 767.98px) {
    .about-collumn-section {
      margin-top: 4rem; } }

.aboutus-offer-section {
  background: radial-gradient(circle at 115% 100%, #3e68ff, #1a46e5 40%, #0024a8);
  position: relative;
  overflow: hidden; }
  .aboutus-offer-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../../assets/images/sec-3bg.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.5; }
  .aboutus-offer-section .backstyle {
    position: relative;
    z-index: 2; }
    .aboutus-offer-section .backstyle .collumn-wrappper img {
      max-width: 50px; }
  .aboutus-offer-section .team-card {
    margin-top: 4rem; }

.featured-logo.about .swiper-slide img {
  max-width: 120px; }

.news-only.detail-section {
  margin-top: -330px; }

@media (max-width: 1199.98px) {
  .news-only.detail-section {
    margin-top: -230px; } }

@media (max-width: 991.98px) {
  .news-only.detail-section {
    margin-top: -150px; } }
