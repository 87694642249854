//Extentiosn

%bg-gradient-white {
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgb(248, 250, 255)
  );
}

//Footer
footer {
  @extend %bg-gradient-white;
  border-top: 1px solid lighten($pale-blue, 5%);
  .footer-sec-1,
  .footer-sec-2,
  .footer-sec-3,
  .footer-sec-4 {
    padding-top: 3rem;
    padding-bottom: 3rem;
    ul {
      margin-bottom: 0px;

      li {
        a,
        .a {
          line-height: 2.3rem;

          &:hover,
          &:focus {
            color: $danger;
          }

          &:active {
            color: $danger;
            font-weight: 500;
          }
        }
        .socialMedia {
          .list-inline-item {
            margin-right: 0px;
          }
        }
      }
    }
    &.nickx, &.mtn {
      ul {
        li {
          a,
          .a {
            &:hover,
            &:focus {
              color: $orange;
            }

            &:active {
              color: $orange;
            }
          }
        }
      }
    }
    &.mtn {
      ul {
        li {
          a,
          .a {
            &:hover,
            &:focus {
              color: $footerOrange;
            }

            &:active {
              color: $footerOrange;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      ul {
        min-width: 200px;
        li {
          font-size: 16px;
          font-weight: normal;
          line-height: 2.5rem;
        }
      }
      .footer-logo {
        padding-top: 2rem;
        padding-bottom: 0px;
        .mainLogo {
          width: 10rem;
        }
      }

      padding-top: 2rem;
      padding-bottom: 4rem;
    }
  }

  .pl-custom {
    padding-left: 5%;
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }
  }

  .copyright {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    p {
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 12px;
    }
    @include media-breakpoint-down(md) {
      height: 25px;
    }
  }

  .gutter-space {
    padding: 0px 15px;

    [class^="col"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  h4 {
    font-weight: 500;
  }
  &.mb-65-responsive {
    @include media-breakpoint-down(sm) {
      position: relative;
      bottom: 65px;
    }
  }
  .footer-wrapper {
    box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
  }

  .footer-afterLogin {
    padding: 8px 0px;
    box-shadow: 0 10px 12px 20px rgba(86, 119, 151, 0.08);
    background-image: linear-gradient(to top, #ffffff, #f8fbfe);
    display: flex;
    flex-wrap: nowrap;

    a {
      figure {
        figcaption {
          font-size: 12px;
          font-weight: 500;
          color: $bluish-grey;
        }
      }
      .on {
        display: none;
      }
      &.active {
        .off {
          display: none;
        }
        .on {
          display: block;
        }
        figure {
          figcaption {
            color: $danger;
          }
        }
      }
    }
    [class^="col"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.badge-notification-count-responsive {
  position: absolute;
  left: auto;
  margin-left: 10px;
  top: -2px;
}
