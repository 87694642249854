/*---
Custom Color add to theme
---*/
.about-mtn-treasure {
  background-image: linear-gradient(to top, #ffffff, #f8faff); }
  .about-mtn-treasure .swiper-slide {
    width: auto; }

.about-mtn-treasure .view-all {
  font-weight: 500;
  font-size: 16px;
  color: #1a46e5; }
  @media (max-width: 991.98px) {
    .about-mtn-treasure .view-all {
      font-size: 12px;
      font-weight: bold; } }

.event-section {
  margin-top: -150px; }

.detail-section {
  margin-top: -280px; }
  .detail-section .news-avatar {
    width: 120px;
    height: 120px;
    overflow: hidden;
    background: #ccc;
    border: 3px solid #fff;
    box-shadow: 0 2px 23px -8px rgba(60, 64, 85, 0.4);
    border-radius: 60px; }
    .detail-section .news-avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top; }
  .detail-section .bannerImg-section {
    width: 100%;
    height: 390px;
    border-radius: 16px;
    background: #ccc;
    overflow: hidden;
    box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.2); }
    .detail-section .bannerImg-section img {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 100%; }
  .detail-section .media .avatar {
    width: 88px;
    height: 88px;
    border-radius: 44px; }
  .detail-section .media .media-body li img {
    width: 28px;
    height: 28px; }
  .detail-section .text-lightGray {
    color: #7b8997; }

@media (max-width: 1199.98px) {
  .detail-section {
    margin-top: -180px; }
    .detail-section .news-avatar {
      width: 80px;
      height: 80px; } }

@media (max-width: 991.98px) {
  .event-section {
    margin-top: -50px; }
  .detail-section {
    margin-top: -150px; }
    .detail-section .news-avatar {
      width: 80px;
      height: 80px; } }

@media (max-width: 767.98px) {
  .detail-section {
    margin-top: -150px; }
    .detail-section .news-avatar {
      width: 80px;
      height: 80px; } }

@media (max-width: 575.98px) {
  .detail-section {
    margin-top: -100px; }
    .detail-section .news-avatar {
      width: 80px;
      height: 80px; } }

.news-wrapper {
  background-image: linear-gradient(to bottom, #ffffff, #f8faff); }

.event-section .form-row {
  margin-left: 0px; }
  .event-section .form-row ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3c4055;
    opacity: 1;
    /* Firefox */ }
  .event-section .form-row :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3c4055; }
  .event-section .form-row ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3c4055; }

.about-mtn-treasure .tournament-accordian .card {
  background: transparent; }
  .about-mtn-treasure .tournament-accordian .card.bb-1 {
    box-shadow: inset 0 -1px 0 0 #e0e4e8; }
  .about-mtn-treasure .tournament-accordian .card .card-header {
    background: transparent;
    padding: 25px 0px;
    cursor: pointer; }
    .about-mtn-treasure .tournament-accordian .card .card-header .arrow {
      transition: all 0.5s ease-in-out; }
  .about-mtn-treasure .tournament-accordian .card .card-body {
    padding: 0px 0px 25px 0px; }
    .about-mtn-treasure .tournament-accordian .card .card-body img {
      max-width: 65px; }

@media (max-width: 991.98px) {
  .about-mtn-treasure p br {
    display: none; } }

.accordion {
  border: 0px solid rgba(0, 0, 0, 0.1); }

.card-header {
  outline: none; }

#root {
  outline: none !important; }

.help-support {
  margin-left: 3vw;
  background-image: none;
  width: 100%;
  height: 100%; }
  .help-support .contents {
    position: absolute;
    width: 60%;
    height: 60%; }
    .help-support .contents h2 {
      left: -25px;
      position: relative; }
    .help-support .contents h5 {
      left: -18px;
      position: relative; }
  @media (max-width: 1199.98px) {
    .help-support .contents h2 {
      left: 35px;
      font-size: 32px; }
    .help-support .contents h5 {
      left: 38px; } }
  @media (max-width: 991.98px) {
    .help-support {
      background-color: #3e68ff;
      margin-left: 0px;
      margin-top: 0px; }
      .help-support.nickX {
        background-color: #fc7323; }
      .help-support.mtn {
        background-color: #ff5600; }
      .help-support svg {
        display: none; }
      .help-support .contents {
        position: relative;
        width: 100%;
        height: 100%;
        margin-left: 0px;
        padding: 20px 0px; }
        .help-support .contents h2 {
          font-size: 32px;
          left: -15px;
          position: relative; }
        .help-support .contents h5 {
          left: -15px;
          position: relative; }
      .help-support .mt-6 {
        margin-top: 2rem; }
      .help-support .mb-6 {
        margin-bottom: 2rem; } }
  @media (max-width: 767.98px) {
    .help-support .contents h2 {
      position: relative; }
    .help-support .contents h5 {
      position: relative; } }

.bg-light-gradient {
  background-image: linear-gradient(to top, #ffffff, #f8faff); }

.no-premium .banner-wrapper .banner {
  margin-top: 112px; }

.position-relative {
  position: relative !important; }
